import React, { useState, useEffect,useContext } from "react";
import "./ProfileEdit.css";
import { useAuth0 } from "@auth0/auth0-react";
import { ACTION_POST } from '../utils/AxiosActions'
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { AppContext } from './AppProvider'

const ProfileEdit = () => {
  const [user] = useContext(AppContext);
  const [edit] = useState(true);
  const { getIdTokenClaims } = useAuth0();
  const [errors, setErrors] = useState({});
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const jobOptions = ["会社員", "自営業", "学生", "専業主婦", "その他"];

  
  const [formData, setFormData] = useState({
    nickname: user?.nickname || '',
    name: user?.name || '',
    email: user?.email || '',
    zip_code: user?.zip_code || '',
    prefecture: user?.prefecture || '',
    city: user?.city || '',
    address: user?.address || '',
    building: user?.building || '',
    phone_number: user?.phone_number || '',
    gender: user?.gender,
    birth_year: user?.birth_year,
    birth_month: user?.birth_month,
    birth_day: user?.birth_day,
    receive_dm: user?.receive_dm || '',
    receive_email_newsletter: user?.receive_email_newsletter || '',
    is_marry: user?.is_marry || '',
    wedding_anniversary_year: user?.wedding_anniversary_year || '',
    wedding_anniversary_month: user?.wedding_anniversary_month || '',
    wedding_anniversary_day: user?.wedding_anniversary_day || '',
    job: user?.job || '',
    birthdate:user?.birthdate || '',
    wedding_anniversary:user?.wedding_anniversary || null,
    edit:true
  });

  useEffect(() => {
    document.title = `登録情報変更 | FDCP`;
    setFormData(user)
    }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    if (name === "zip_code" && window.AjaxZip3) {
      const cleanedZip = value.replace("-", "");

      if (/^\d{7}$/.test(cleanedZip)) {
        const shouldAutoFill =
          !formData.prefecture.trim() && !formData.city.trim();
  
        if (shouldAutoFill) {
          window.AjaxZip3.zip2addr("zip_code", "", "prefecture", "city");
  
          window.AjaxZip3.onSuccess = () => {
            const prefecture = document.getElementById("prefecture")?.value || "";
            const city = document.getElementById("city")?.value || "";
            setFormData(prev => ({
              ...prev,
              prefecture,
              city,
            }));
          };
        }
      }
    }
  };


  const validate = () => {
    const newErrors = {};
    if (!formData.nickname) newErrors.nickname = '名前は必須です。';
    if (!formData.name) newErrors.name = 'お名前（フリガナ）は必須です。';
    if (!formData.email) {
      newErrors.email = 'メールアドレスは必須です。';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'メールアドレスの形式が間違っています。';
    }
    if (!formData.zip_code) newErrors.zip_code = '郵便番号は必須です。';
    if (!formData.prefecture) newErrors.prefecture = '都道府県は必須です。';
    if (!formData.city) newErrors.city = '市区町村は必須です。';
    if (!formData.address) newErrors.address = '番地は必須です。';
    // if (!formData.building) newErrors.building = '建物号室は必須です。';
    if (!formData.phone_number) newErrors.phone_number = '電話番号は必須です。';
    if (!formData.gender) newErrors.gender = '性別は必須です。';
    if (!formData.birth_year || !formData.birth_month || !formData.birth_day) newErrors.birthdate = '生年月日は必須です。';
    else if (isNaN(new Date(formData.birth_year+'-'+formData.birth_month+'-'+formData.birth_day))) newErrors.birthdate = '生年月日の日付形式が間違っています。';
    if (!formData.receive_dm) newErrors.receive_dm = 'DMのチェックは必須です。';
    if (!formData.receive_email_newsletter) newErrors.receive_email_newsletter = 'メールマガジン可否は必須です。';
    if (formData.wedding_anniversary_year && isNaN(new Date(formData.wedding_anniversary_year+'-'+formData.wedding_anniversary_month+'-'+formData.wedding_anniversary_day))) {
      newErrors.wedding_anniversary = '結婚記念日の日付形式が間違っています。';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (window.confirm("この内容で送信します、よろしいですか？")) {
      setIsSubmitting(true);
      setErrors({});
      const validationErrors = validate();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        window.scrollTo(0, 0);
        return;
      }
      try {
        const idTokenClaim = await getIdTokenClaims();
        const apiUrl = process.env.REACT_APP_API_URL;
        let url = apiUrl+'/api/fdcpcrm/updateuser'
        let config = {
                headers: {
                    Authorization: idTokenClaim.__raw
                }
            }
            formData.birthdate = formData.birth_year+'-'+formData.birth_month+'-'+formData.birth_day;
            if (formData.wedding_anniversary_year && formData.wedding_anniversary_month && formData.wedding_anniversary_day) {
                formData.wedding_anniversary = formData.wedding_anniversary_year+'-'+formData.wedding_anniversary_month+'-'+formData.wedding_anniversary_day;
            }
            console.log(formData);
        return new Promise((resolve, reject) => {
            ACTION_POST(url, formData,config)
                .then((response) => {
                    if (response.user) {
                        toast.success("会員情報が変更されました", {
                            showHideTransition: 'fade', // fade, slide or plain
                            allowToastClose: false, // Boolean value true or false
                            position: 'mid-center', // bottom-left or bottom-right or bottom-center or top-left or top-right or top-center or mid-center or an object representing the left, right, top, bottom values
                            className: "toast-message",
                            icon:false,
                            tapToDismissEnabled:true,
                            autoClose:3000
                    
                          });
                      setTimeout(() => {
                        window.location.href = '/';
                      }, 1000);
                    }
            
                    if (!response.user) {
                        setErrors("失敗しました");
                      toast.error("失敗しました", {
                        showHideTransition: 'fade', // fade, slide or plain
                        allowToastClose: false, // Boolean value true or false
                        position: 'mid-center', // bottom-left or bottom-right or bottom-center or top-left or top-right or top-center or mid-center or an object representing the left, right, top, bottom values
                        className: "toast-message",
                        icon:false,
                        tapToDismissEnabled:true,
                        autoClose:3000
                
                      });
                    }
                    // setUser(response.user)
                    resolve(response);
                })
                .catch((error) => {
                reject(error);
                });
            });
      } catch (error) {
        console.error('Submit failed:', error);
        setIsSubmitting(false);
      }
    }
  };

  const parseFormData = (data) => {
    const returnJson = {};
    data.forEach(([key, value]) => {
      returnJson[key] = value;
    });
    return returnJson;
  };

  const handleTermsChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  return (
    <div className="profile-container">
      <div className="padding-side">
        <p className="font-xl">{edit ? '登録情報変更' : '会員登録'}</p>
      </div>
      <ToastContainer hideProgressBar />

      <form onSubmit={handleSubmit}>
        <input type="hidden" name="edit" value={edit} />
        
        <ul className="ul-items">
          {/* Nickname */}
          <li className="li-item">
            <div className="input-set">
              <label htmlFor="nickname">お名前</label><label className="require">必須</label>
              <input
                id="nickname"
                className={errors?.nickname ? 'error' : ''}
                type="text"
                name="nickname"
                value={formData?.nickname}
                onChange={handleChange}
              />
              {errors?.nickname && <p className="error-message">{errors?.nickname}</p>}
            </div>
          </li>

          {/* Name */}
          <li className="li-item not-first">
            <div className="input-set">
              <label htmlFor="name">お名前（フリガナ）</label><label className="require">必須</label>
              <input
                id="name"
                className={errors?.name ? 'error' : ''}
                type="text"
                name="name"
                value={formData?.name}
                onChange={handleChange}
              />
              {errors?.name && <p className="error-message">{errors?.name}</p>}
            </div>
          </li>

          {/* Email */}
          <li className="li-item not-first">
            <div className="input-set">
              <label htmlFor="email">メールアドレス</label><label className="require">必須</label>
              <input
                id="email"
                className={errors?.email ? 'error' : ''}
                type="text"
                name="email"
                value={formData?.email}
                onChange={handleChange}
              />
              {errors?.email && <p className="error-message">{errors?.email}</p>}
            </div>
          </li>

          <li className="li-item not-first">
          <div className="input-set">
            <label htmlFor="zip_code">郵便番号</label><label className="require">必須</label>
            <input
              id="zip_code"
              className={errors?.zip_code ? 'error' : ''}
              type="text"
              name="zip_code"
              value={formData?.zip_code}
              onChange={handleChange}
            />
            {errors?.zip_code && <p className="error-message">{errors.zip_code}</p>}
            <p className="error-message js-error error-zip_code"></p>
          </div>
        </li>
        
        <li className="li-item not-first">
          <div className="input-set">
            <label htmlFor="prefecture">都道府県</label><label className="require">必須</label>
            <input
              id="prefecture"
              className={errors?.prefecture ? 'error' : ''}
              type="text"
              name="prefecture"
              value={formData?.prefecture}
              onChange={handleChange}
            />
            {errors?.prefecture && <p className="error-message">{errors.prefecture}</p>}
            <p className="error-message js-error error-prefecture"></p>
          </div>
        </li>

        <li className="li-item not-first">
          <div className="input-set">
            <label htmlFor="city">住所1（市区町村）</label><label className="require">必須</label>
            <input
              id="city"
              className={errors?.city ? 'error' : ''}
              type="text"
              name="city"
              value={formData?.city}
              onChange={handleChange}
            />
            {errors?.city && <p className="error-message">{errors.city}</p>}
            <p className="error-message js-error error-city"></p>
          </div>
        </li>

        <li className="li-item not-first">
          <div className="input-set">
            <label htmlFor="address">住所2（番地）</label><label className="require">必須</label>
            <input
              id="address"
              className={errors?.address ? 'error' : ''}
              type="text"
              name="address"
              value={formData?.address}
              onChange={handleChange}
            />
            {errors?.address && <p className="error-message">{errors.address}</p>}
            <p className="error-message js-error error-address"></p>
          </div>
        </li>

        <li className="li-item not-first">
          <div className="input-set">
            <label htmlFor="building">住所3（建物号室）</label>
            <input
              id="building"
              className={errors?.building ? 'error' : ''}
              type="text"
              name="building"
              value={formData?.building}
              onChange={handleChange}
            />
            {errors?.building && <p className="error-message">{errors.building}</p>}
            <p className="error-message js-error error-building"></p>
          </div>
        </li>

        <li className="li-item not-first">
          <div className="input-set">
            <label htmlFor="phone_number">電話番号</label><label className="require">必須</label>
            <input
              id="phone_number"
              className={errors?.phone_number ? 'error' : ''}
              type="text"
              name="phone_number"
              value={formData?.phone_number}
              onChange={handleChange}
            />
            {errors?.phone_number && <p className="error-message">{errors.phone_number}</p>}
            <p className="error-message js-error error-phone_number"></p>
          </div>
        </li>

          {/* Sex */}
          <li className="li-item not-first">
            <div className="input-set">
              <label htmlFor="gender">性別</label><label className="require">必須</label>
              <div className="radio-set">
                <label>
                  <input
                    type="radio"
                    name="gender"
                    value="female"
                    checked={formData?.gender === 'female'}
                    onChange={handleChange}
                  />
                  女性
                </label>
                <label>
                  <input
                    type="radio"
                    name="gender"
                    value="male"
                    checked={formData?.gender === 'male'}
                    onChange={handleChange}
                  />
                  男性
                </label>
              </div>
              {errors?.gender && <p className="error-message">{errors?.gender}</p>}
            </div>
          </li>

          <li className="li-item not-first">
          <div className="input-set">
            <label htmlFor="birthday">生年月日</label><label className="require">必須</label>
            <div>
              <div className="select-set">
                <select name="birth_year" value={formData?.birth_year} onChange={handleChange}>
                  <option value="">--</option>
                  {Array.from({ length: 100 }, (_, i) => {
                    const year = new Date().getFullYear() - i;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>年
              </div>
              <div className="select-set">
                <select name="birth_month" value={formData?.birth_month} onChange={handleChange}>
                  <option value="">--</option>
                  {Array.from({ length: 12 }, (_, i) => {
                    const month = String(i + 1).padStart(2, '0');
                    return (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    );
                  })}
                </select>月
              </div>
              <div className="select-set">
                <select name="birth_day" value={formData?.birth_day} onChange={handleChange}>
                  <option value="">--</option>
                  {Array.from({ length: 31 }, (_, i) => {
                    const day = String(i + 1).padStart(2, '0');
                    return (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    );
                  })}
                </select>日
              </div>
            </div>
            {errors?.birthdate && <p className="error-message">{errors?.birthdate}</p>}
          </div>
        </li>

        <li className="li-item not-first">
          <div className="input-set">
            <label htmlFor="receive_dm">DMの発送</label><label className="require">必須</label>
            <div className="radio-set">
              <label>
                <input
                  type="radio"
                  name="receive_dm"
                  value="1"
                  checked={formData?.receive_dm === '1'}
                  onChange={handleChange}
                />
                受取る
              </label>
              <label>
                <input
                  type="radio"
                  name="receive_dm"
                  value="0"
                  checked={formData?.receive_dm === '0'}
                  onChange={handleChange}
                />
                受取らない
              </label>
            </div>
          </div>
        </li>

        <li className="li-item not-first">
          <div className="input-set">
            <label htmlFor="receive_email_newsletter">メールマガジン可否</label><label className="require">必須</label>
            <div className="radio-set">
              <label>
                <input
                  type="radio"
                  name="receive_email_newsletter"
                  value="1"
                  checked={formData?.receive_email_newsletter === '1'}
                  onChange={handleChange}
                />
                可
              </label>
              <label>
                <input
                  type="radio"
                  name="receive_email_newsletter"
                  value="0"
                  checked={formData?.receive_email_newsletter === '0'}
                  onChange={handleChange}
                />
                否
              </label>
            </div>
          </div>
        </li>

        <li className="li-item not-first">
          <div className="input-set">
            <label htmlFor="is_marry">未婚/既婚</label>
            <div className="radio-set">
              <label>
                <input
                  type="radio"
                  name="is_marry"
                  value=""
                  checked={formData?.is_marry === ''}
                  onChange={handleChange}
                />
                未入力
              </label>
              <label>
                <input
                  type="radio"
                  name="is_marry"
                  value="1"
                  checked={formData?.is_marry === '1'}
                  onChange={handleChange}
                />
                既婚
              </label>
              <label>
                <input
                  type="radio"
                  name="is_marry"
                  value="0"
                  checked={formData?.is_marry === '0'}
                  onChange={handleChange}
                />
                未婚
              </label>
            </div>
          </div>
        </li>

        <li className="li-item not-first">
          <div className="input-set">
            <label htmlFor="wedding_anniversary">結婚記念日</label>
            <div>
              <div className="select-set">
                <select name="wedding_anniversary_year" value={formData?.wedding_anniversary_year} onChange={handleChange}>
                  <option value="">--</option>
                  {Array.from({ length: 100 }, (_, i) => {
                    const year = new Date().getFullYear() - i;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>年
              </div>
              <div className="select-set">
                <select name="wedding_anniversary_month" value={formData?.wedding_anniversary_month} onChange={handleChange}>
                  <option value="">--</option>
                  {Array.from({ length: 12 }, (_, i) => {
                    const month = String(i + 1).padStart(2, '0');
                    return (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    );
                  })}
                </select>月
              </div>
              <div className="select-set">
                <select name="wedding_anniversary_day" value={formData?.wedding_anniversary_day} onChange={handleChange}>
                  <option value="">--</option>
                  {Array.from({ length: 31 }, (_, i) => {
                    const day = String(i + 1).padStart(2, '0');
                    return (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    );
                  })}
                </select>日
              </div>
            </div>
            {errors?.wedding_anniversary && <p className="error-message">{errors?.wedding_anniversary}</p>}
          </div>
        </li>

        <li className="li-item not-first">
          <div className="input-set">
            <label htmlFor="job">職業</label>
            <div className="select-set" style={{ width: '100%' }}>
              <select name="job" value={formData?.job} onChange={handleChange} style={{ width: '100%' }}>
                <option value="">--</option>
                {jobOptions.map((job) => (
                  <option key={job} value={job}>
                    {job}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </li>
        </ul>
          {/* Submit Button */}
          <div className="padding-side">
            <div className="button-wrapper">
                <input
                className="fdcp-btn btn-full js-submit"
                type="submit"
                value="変更する"
                />
            </div>
            </div>
      </form>
    </div>
  );
};

export default ProfileEdit;
